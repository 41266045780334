import BillingSnap from '@/services/BillingSnap.js';

const BillingCommonFunctions = {  
  methods: {
    async addDaysToDate(date = new Date(), daysToAdd){
      let dateObj = new Date(date)
      let daysToAddParsed = parseInt(daysToAdd)
      if(!daysToAddParsed) return date
   
      dateObj.setDate(dateObj.getDate() + daysToAddParsed);
      return dateObj.toLocaleString('en-GB', { hour12: false });
    },
    async getCompanyBillingSettings(companyCode){
      let defaultSettings = {
        monthly: false,
        tax_type_description: "20% (VAT on income)",
        invoice_due_date_days: 14
      }
      let compSettings = await BillingSnap.getCompanyBillingSettings(null, companyCode)
      return compSettings ? compSettings : defaultSettings
     
    },
    getBillingDueDate(companyCode, date = new Date()) {
      let defaultDays = 14
      let daysToAdd = defaultDays
      let lowerCaseCompanyCode = companyCode.toLowerCase()
      const companiesThirtyDays = ['cor', 'hhu', 'she', '2na', 'v06', 'v12', 'wel', 'hen']
      if(companiesThirtyDays.includes(lowerCaseCompanyCode)){
        daysToAdd = 30
      }
      
      date.setDate(date.getDate() + daysToAdd);
      return date.toLocaleString('en-GB', { hour12: false });
    },
    getCompanyTaxType(companyCode) {
      const defaultTaxType = '20% (VAT on income)';
      if (!companyCode) return defaultTaxType;
      switch (companyCode.toLowerCase()) {
        case 'v03':
        case 'v13':
        case 'fmw':
        case 'ssi':
        case 'hap':
        case 'v12':
        case '3pl':
          return 'NO VAT';
        default:
          return defaultTaxType;
      }
    },
    /* endingDate: Date
     * billing week is from Sunday to Saturday, invoice date is Friday the Friday in between,
     * if day of week is saturday (6) take one day off, otherwise add x days to the date based on day of the week
     */
    getInvoiceDate(endingDate) {
      let date = new Date(endingDate);
      if (!endingDate) return date;

      const dayOfWeek = date.getDay();
      let daysToAdd = 0;
      switch (dayOfWeek) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          daysToAdd = 5 - dayOfWeek;
          break;
        case 6:
          daysToAdd = -1;
          break;
      }
      date.setDate(date.getDate() + daysToAdd);
      // return dateWithOffset instead of date to prevent daylight savings issue
      let timezoneOffset = date.getTimezoneOffset() * 60000
      let dateWithOffset = new Date(date.getTime() + (timezoneOffset * -1))

      return dateWithOffset;
    
    },
    getLastWeekStartDate() {
      let d = new Date();
      let day = d.getDay(),
        diff = d.getDate() - 7 - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      return new Date(d.setDate(diff));
    },
    generateInvoiceNumber(date){
      let invoiceDate = new Date(date).toISOString().substring(0, 10);
      let invoiceStr = invoiceDate.split('-');
      // Create Invoice Name
       return  `${invoiceStr[0].slice(0, 4)}${invoiceStr[1]}${invoiceStr[2]}`;
    },
    /*
     * Group array by a specific key
     */
    groupBy(array, key) {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    },
    checkIfLastInvoiceOfMonth(invoiceNumber){
      // convert invoice number in format 20221230 to date string 2022-12-30
      const invoiceDateString = `${invoiceNumber.substring(0,4)}-${invoiceNumber.substring(4,6)}-${invoiceNumber.substring(6,8)}`
  
      let invoiceDate = new Date(invoiceDateString)
      const invoiceMonth = invoiceDate.getMonth()
     
      // add 7 days to date to get next week 
      let nextWeekDate = new Date(invoiceDate)
      nextWeekDate.setDate(invoiceDate.getDate() + 7)
      const nextWeekMonth = nextWeekDate.getMonth();
      // if they are not the same month it means the invoice number passed is the last invoice of the month
      return invoiceMonth !== nextWeekMonth
    },
    getMonthlyInvoiceNumberXero(companyCode, invoiceNumber){
      if(!invoiceNumber) return ''
      const year = invoiceNumber.substring(0,4)
      const month =  parseInt(invoiceNumber.substring(4,6)) - 1
      const day = invoiceNumber.substring(6,8)
      let date = new Date(year, month, day) 
      const monthStr = date.toLocaleString('en-UK', {month: 'short'});
      return `${companyCode}-${monthStr}-${year}`
    },
    getWeekNumber(dateString){
      Date.prototype.getWeek = function () {
        var onejan = new Date(this.getFullYear(), 0, 1);
        return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
      };

      let dateObj = new Date(dateString)
      return dateObj.getWeek()
    },

    findDuplicateAttribute(array, attribute) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i][attribute] === array[j][attribute]) {
            return { obj1: array[i], obj2: array[j] };
          }
        }
      }
      return null; // No duplicates found
    },
    sortByEmptyAttributeFirst(arr, attribute) {
      return arr.sort((a, b) => {
        if (!a[attribute] && b[attribute]) return -1;
        if (a[attribute] && !b[attribute]) return 1;
        return 0;
      });
    },
    getHeaders(data , filters = []) {
      if(!data || data.length < 1) return []
      
      let headers =  Object.keys(data[0]).map((el) => {
        return { text: el.replace(/_/g, " "), value: el };
      });
      if(filters && filters.length){
        return headers.filter( header =>{
          return !filters.includes(header.value) 
        })
      }
      return headers
    },
    formatDateString(date) {
      if (!date) return "";
      return date.slice(0, 19).replace("T", " ");
    },
  
  },
};

export default BillingCommonFunctions;
